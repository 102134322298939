import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`Platforma Moldova`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` Youth discussions about the future of Moldova`}<br parentName="p"></br>{`
`}<strong parentName="p">{`When:`}</strong>{` 2023`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Where:`}</strong>{` Moldova`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Size:`}</strong>{` 202  `}</p>
    <p><strong parentName="p">{`Details:`}</strong>{` `}</p>
    <p>{`Platforma Moldova is a pilot project facilitated by Initiative for Peace (I4P), UN Moldova, and swisspeace with the objective of engaging young people in Moldova in political conversations and mapping the topics that are most important to this demographic. The pilot project asked the question, “What should the government do to make Moldova a better place to live?”  `}</p>
    <p><strong parentName="p">{`Overview:`}</strong>{`  `}</p>
    <p>{`Initiative for Peace (I4P), UN Moldova, and swisspeace launched a pilot project using Pol.is in the spring of 2023, asking young people in Moldova what they believed the government should do to make Moldova a better place to live. The objective of the project was to understand the views of youth about the future of Moldova in their own words in order to start policy conversations with decision-makers. An additional aim was to test and refine a concept for integrating the use of Pol.is into larger political and peace processes and public events.
The team engaged youth through a targeted outreach campaign to Moldovan university students, youth ambassadors, and youth councils within the networks of I4P and UN Moldova, while swisspeace advised on the digital process and moderation design and data analysis. Over three weeks, 202 young people contributed 109 different statements and cast 5570 votes. Participants could add statements in either Romanian or Russian and the project contributed a Romanian translation of Pol.is to the platform.
The main themes with majority support highlighted the need for education reforms, job creation, increased wages, improving living conditions and protecting the environment. At the same time, two separate opinion groups formed, which were divided on issues such as EU membership and relations with or union with Romania. The digital conversation also helped to highlight other topics that are less prominent in wider political conversations in Moldova, such as high support for same sex marriage, underscoring the importance of bringing youth voices into political and policy debates.
The results of the pilot project were shared at an in person, live event that was also livestreamed. The idea being to bring digital conversations into offline processes and to bring together some of the youth participants and key leaders related to their topics of interest, to discuss these points further. `}</p>
    <h4>{`Links:`}</h4>
    <ul>
      <li parentName="ul">{`Project page: `}<a parentName="li" {...{
          "href": "https://i4p-md.org/forum/"
        }}>{`https://i4p-md.org/forum/`}</a>{` `}</li>
      <li parentName="ul">{`Pol.is report: `}<a parentName="li" {...{
          "href": "https://pol.is/report/r9zwpbtjb3bdjn7wr72h3"
        }}>{`https://pol.is/report/r9zwpbtjb3bdjn7wr72h3`}</a>{` `}</li>
      <li parentName="ul">{`Recording of the live event (in Romanian with simultaneous interpretation in Russian and English) `}<a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLLpdeITR15mIsEse-nyHHDMTHEX4HgFZp"
        }}>{`https://www.youtube.com/playlist?list=PLLpdeITR15mIsEse-nyHHDMTHEX4HgFZp`}</a>{` `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      